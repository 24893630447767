/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/

@media all {
  /* Never show page breaks in normal view */
  .page-break-after,
  .page-break-before {
    display: none;
  }
}

@media print {
  /* html and body tweaks */
  html,
  body {
    height: auto !important;
    overflow: initial !important;
  }

  /* Page breaks */
  .page-break-after {
    display: block;
    page-break-after: always;
    position: relative;
  }

  .page-break-before {
    display: block;
    page-break-before: always;
    position: relative;
  }

  /* General styles */
  app {
    fuse-sidebar,
    navbar,
    toolbar,
    footer,
    .theme-options-button,
    fuse-theme-options,
    .ps > .ps__rail-x,
    .ps > .ps__rail-y {
      display: none !important;
    }

    #main,
    #container-1,
    #container-2,
    #container-3 {
      padding: 0 !important;
    }

    .ps {
      overflow: visible !important;
    }

    .mat-drawer-container,
    .mat-sidenav-container {
      background-color: white !important;

      .mat-drawer-content,
      .mat-sidenav-content {
        overflow: initial !important;
        height: auto !important;
      }
    }
  }
}
