// Import Fuse core library
@import './@fuse/scss/core';
@import 'print';
// Import app.theme.scss
@import './app/app.theme';

@import '~ngx-toastr/toastr.css';
@import '~@ng-select/ng-select/themes/material.theme.css';
@import './scss/dropzone';

.toolbar-actions {
  mat-form-field {
    min-width: 200px;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
  font-size: 16px;
  .option-text-format {
    display: block;
    line-height: 1;
    padding: 0.5em 0 0.5em;
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container {
  .ng-value,
  .ng-input {
    font-size: 16px;
    line-height: 32px;
  }
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 16px;
  line-height: 32px;
}

// .mat-dialog-content,
// .mat-dialog-container {
//   overflow: visible !important;
// }

.mat-dialog-container {
  overflow: visible !important;
}

.mat-checkbox,
.mat-radio-button {
  margin-right: 15px;
}

.mat-dialog-actions {
  clear: both;
}

.mat-checkbox,
.mat-radio-button {
  margin-right: 15px;
}

.mat-checkbox-label {
  white-space: normal;
}

.text-danger {
  color: $red;
}
